<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <!-- form -->
    <validation-observer ref="AssetTypeAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addAssetType">
        <!-- Name -->
        <b-form-group label="Asset Type Name" label-for="AssetType Name">
          <template #label>
            <span>Asset Type Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="asset_type_name"
            :rules="{ regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/ }"
          >
            <b-form-input
              id="asset_type_name"
              v-model="asset_type_name"
              :state="errors.length > 0 ? false : null"
              name="asset_type_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Asset Type Description"
          label-for="AssetType Description"
        >
          <b-form-textarea
            id="asset_type_description"
            v-model="asset_type_description"
            name="asset_type_description"
            rows="3"
            max-rows="3"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || asset_type_name == '' || disable"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      asset_type_name: "",
      asset_type_description: "",
      isloading: false,
      disable: false,
    };
  },
  //   created: function () {
  //     // this.load();
  //   },
  methods: {
    addAssetType: function () {
      this.$refs.AssetTypeAddForm.validate().then((success) => {
        this.disable = true;
        this.isloading = true;

        if (success) {
          //call to axios
          console.log("success");
          let data = {
            asset_type_name: this.asset_type_name,
            asset_type_description: this.asset_type_description,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "asset/asset-type/",
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.asset_type_id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Added Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
              this.disable = false;
              this.$router.push({ name: "AssetTypes" });
            }
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>